import React, { Suspense, lazy, useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Layout, ConfigProvider, Spin } from 'antd';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import PrivateRoute from './components/Common/PrivateRoute';


// Layout Components
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';

// Core Pages
const Dashboard = lazy(() => import('./pages/Dashboard'));
const ShareholdersPage = lazy(() => import('./pages/ShareholdersPage'));
const TransactionsPage = lazy(() => import('./pages/TransactionsPage'));
const CompanyProfile = lazy(() => import('./pages/CompanyProfile'));
const Login = lazy(() => import('./components/Auth/Login'));
const Register = lazy(() => import('./components/Auth/Register'));
const ForgotPassword = lazy(() => import('./components/Auth/ForgotPassword'));
const NotFound = lazy(() => import('./pages/NotFound'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const RegistrationSuccess = lazy(() => import('./pages/RegistrationSuccess'));

// Legal Pages
const PrivacyPolicy = lazy(() => import('./pages/legal/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/legal/TermsOfService'));
const DataProtection = lazy(() => import('./pages/legal/DataProtection'));
const Compliance = lazy(() => import('./pages/legal/Compliance'));
const Security = lazy(() => import('./pages/legal/Security'));
const GoodbyePage = lazy(() => import('./pages/GoodbyePage'));

// Board Resolutions Page
const BoardResolutionsPage = lazy(() => import('./pages/BoardResolutionsPage'));

const { Content } = Layout;

function App() {
  return (
    <ConfigProvider>
      <AuthProvider>
        <Suspense fallback={
          <div style={{ 
            height: '100vh', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            background: '#f0f2f5'
          }}>
            <Spin size="large" />
          </div>
        }>
          <AppContent />
        </Suspense>
      </AuthProvider>
    </ConfigProvider>
  );
}

const AppContent = () => {
  const { currentUser, authLoading } = useContext(AuthContext);
  const location = useLocation();
  
  const publicRoutes = ['/', '/login', '/register', '/forgot-password'];
  const legalRoutes = [
    '/privacy-policy',
    '/terms-of-service',
    '/data-protection',
    '/compliance',
    '/security'
  ];
  
  const isPublicRoute = publicRoutes.includes(location.pathname);
  const isLegalRoute = legalRoutes.includes(location.pathname);

  if (authLoading) {
    return (
      <div style={{ 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        background: '#f0f2f5'
      }}>
        <Spin size="large" />
      </div>
    );
  }

  // If user is authenticated and tries to access public routes except '/', redirect to dashboard
  if (currentUser && isPublicRoute && location.pathname !== '/') {
    return <Navigate to="/dashboard" replace />;
  }

  // If it's a public or legal route, don't show the app layout
  if (isPublicRoute || isLegalRoute) {
    return <AppRoutes />;
  }

  // For all other routes, use the app layout
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Content style={{ padding: '24px', background: '#fff' }}>
        <AppRoutes />
      </Content>
      <Footer />
    </Layout>
  );
};

const AppRoutes = () => {
  const { currentUser } = useContext(AuthContext);

  const legalRoutes = (
    <>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/data-protection" element={<DataProtection />} />
      <Route path="/compliance" element={<Compliance />} />
      <Route path="/security" element={<Security />} />
    </>
  );

  if (currentUser) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route 
          path="/dashboard" 
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/shareholders" 
          element={
            <PrivateRoute>
              <ShareholdersPage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/transactions" 
          element={
            <PrivateRoute>
              <TransactionsPage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/company-profile" 
          element={
            <PrivateRoute>
              <CompanyProfile />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/board-resolutions" 
          element={
            <PrivateRoute>
              <BoardResolutionsPage />
            </PrivateRoute>
          } 
        />
        {legalRoutes}
        <Route path="/goodbye" element={<GoodbyePage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  // Not authenticated, show public routes
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/registration-success" element={<RegistrationSuccess />} />
      {legalRoutes}
      <Route path="/goodbye" element={<GoodbyePage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;