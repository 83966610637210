// src/components/Common/PrivateRoute.js

import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Spin } from 'antd';
import { AuthContext } from '../../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const PrivateRoute = ({ children }) => {
  const { currentUser, authLoading } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [hasCompanyProfile, setHasCompanyProfile] = useState(false);

  useEffect(() => {
    const checkCompanyProfile = async () => {
      if (currentUser) {
        try {
          const companyRef = doc(db, 'companies', currentUser.uid);
          const companySnap = await getDoc(companyRef);
          setHasCompanyProfile(companySnap.exists());
        } catch (error) {
          console.error('Error checking company profile:', error);
        }
      }
      setLoading(false);
    };

    checkCompanyProfile();
  }, [currentUser]);

  if (authLoading || loading) {
    return (
      <div style={{ 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If on company profile page, allow access regardless of profile completion
  if (window.location.pathname === '/company-profile') {
    return children;
  }

  // For other protected routes, check if company profile exists
  if (!hasCompanyProfile) {
    return <Navigate to="/company-profile" />;
  }

  return children;
};

export default PrivateRoute;
