// src/components/Layout/Header.js
import React, { useContext } from 'react';
import { Layout, Menu, Dropdown, Avatar, Typography } from 'antd';
import { 
  LogoutOutlined, 
  SettingOutlined,
  DashboardOutlined,
  TeamOutlined,
  SwapOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/Header.css'
import LumioLogo from '../../assets/LumioLogo';

const { Header } = Layout;
const { Title } = Typography;

const AppHeader = () => {
  const { currentUser, companyData, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="1" icon={<SettingOutlined />}>
        <Link to="/company-profile">
          Company Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const getNavItems = (role) => {
    const items = [
      {
        key: '/dashboard',
        icon: <DashboardOutlined />,
        label: 'Dashboard',
      },
      {
        key: '/shareholders',
        icon: <TeamOutlined />,
        label: 'Shareholders',
      },
      {
        key: '/transactions',
        icon: <SwapOutlined />,
        label: 'Transactions',
      },
      {
        key: '/board-resolutions',
        icon: <FileTextOutlined />,
        label: 'Board Resolutions',
      },
    ];

    return items;
  };

  return (
    <div className="platform-header">
      <Header className="main-header">
        <div className="header-logo-section">
          <div className="logo-wrapper">
            <LumioLogo />
          </div>
          <Title level={4}>lumioCapital <span className="version-tag">v0.5.4</span></Title>
        </div>

        {currentUser && (
          <div className="user-section">
            <Dropdown 
              overlay={profileMenu} 
              placement="bottomRight" 
              trigger={['click']}
            >
              <div className="user-profile">
                <span className="company-name">
                  {companyData?.companyName}
                </span>
                <Avatar className="user-avatar">
                  {companyData?.companyName?.charAt(0)?.toUpperCase()}
                </Avatar>
              </div>
            </Dropdown>
          </div>
        )}
      </Header>

      {currentUser && (
        <Menu
          mode="horizontal"
          selectedKeys={[location.pathname]}
          className="platform-menu"
          items={getNavItems(currentUser?.role).map(item => ({
            key: item.key,
            icon: item.icon,
            label: <Link to={item.key}>{item.label}</Link>,
          }))}
        />
      )}
    </div>
  );
};

export default AppHeader;