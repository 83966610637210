import React from 'react';

const LumioLogo = ({ width = 60, height = 60, style }) => (
  <svg 
    width={width} 
    height={height} 
    viewBox="0 0 1080 1080" 
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      minWidth: width,
      minHeight: height,
      ...style
    }}
  >
    <circle cx="539.5" cy="539.5" r="55.5" />
    <circle cx="679.5" cy="462.5" r="55.5" />
    <circle cx="398.5" cy="462.5" r="55.5" />
    <ellipse cx="399" cy="301.5" rx="37" ry="36.5" />
    <circle cx="265" cy="540" r="37" />
    <circle cx="265.5" cy="376.5" r="24.5" />
    <circle cx="265.5" cy="703.5" r="24.5" />
    <circle cx="814.5" cy="703.5" r="24.5" />
    <ellipse cx="540" cy="856.5" rx="25" ry="24.5" />
    <ellipse cx="540" cy="224.5" rx="25" ry="24.5" />
    <circle cx="814.5" cy="376.5" r="24.5" />
    <circle cx="816" cy="540" r="37" />
    <ellipse cx="399" cy="783.5" rx="37" ry="36.5" />
    <ellipse cx="682" cy="783.5" rx="37" ry="36.5" />
    <ellipse cx="682" cy="301.5" rx="37" ry="36.5" />
    <ellipse cx="679.5" cy="620" rx="55.5" ry="55" />
    <ellipse cx="398.5" cy="620" rx="55.5" ry="55" />
    <circle cx="539.5" cy="375.5" r="55.5" />
    <circle cx="539.5" cy="719.5" r="55.5" />
  </svg>
);

export default LumioLogo; 