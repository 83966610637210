import React, { createContext, useState, useEffect } from 'react';
import { 
  getAuth, 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail
} from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  const auth = getAuth();
  const db = getFirestore();

  const register = async (userData) => {
    try {
      // Validate all required fields
      const requiredFields = [
        'email', 'password', 'firstName', 'lastName', 'phone',
        'companyName', 'cin', 'authorisedCapital', 'paidUpCapital',
        'nominalShareValue'
      ];

      const missingFields = requiredFields.filter(field => !userData[field]);
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }

      // First check if email already exists
      const methods = await fetchSignInMethodsForEmail(auth, userData.email);
      if (methods && methods.length > 0) {
        throw new Error('This email is already registered. Please use a different email or try logging in.');
      }

      // Create authentication record with error handling
      let userCredential;
      try {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          userData.email,
          userData.password
        );
      } catch (authError) {
        console.error('Authentication error:', authError);
        if (authError.code === 'auth/email-already-in-use') {
          throw new Error('This email is already registered. Please use a different email or try logging in.');
        } else if (authError.code === 'auth/invalid-email') {
          throw new Error('The email address is not valid.');
        } else if (authError.code === 'auth/operation-not-allowed') {
          throw new Error('Email/Password authentication is not enabled. Please contact support.');
        } else if (authError.code === 'auth/weak-password') {
          throw new Error('The password is too weak. Please use a stronger password.');
        } else if (authError.code === 'auth/admin-restricted-operation') {
          throw new Error('Registration is currently disabled. Please try again later or contact support.');
        } else if (authError.code === 'auth/missing-identifier') {
          throw new Error('Please ensure all required fields are filled out correctly.');
        }
        throw new Error(authError.message || 'Failed to create account. Please try again.');
      }

      if (!userCredential || !userCredential.user) {
        throw new Error('Failed to create user account. Please try again.');
      }

      // Create company profile in Firestore
      const companyData = {
        companyName: userData.companyName,
        cin: userData.cin,
        email: userData.email,
        authorizedCapital: Number(userData.authorisedCapital),
        paidUpCapital: Number(userData.paidUpCapital),
        nominalShareValue: Number(userData.nominalShareValue),
        // User details
        ownerName: `${userData.firstName} ${userData.lastName}`,
        phone: userData.phone,
        dateOfBirth: userData.dateOfBirth ? userData.dateOfBirth.format('YYYY-MM-DD') : null,
        // Metadata
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        profileComplete: true,
        // Add user reference
        userId: userCredential.user.uid
      };

      // Validate company data
      if (!companyData.companyName || !companyData.cin) {
        await userCredential.user.delete();
        throw new Error('Company information is incomplete');
      }

      if (!companyData.authorizedCapital || !companyData.paidUpCapital || !companyData.nominalShareValue) {
        await userCredential.user.delete();
        throw new Error('Capital information is incomplete');
      }

      // Log sanitized data for debugging
      console.log('Creating company profile:', {
        companyName: companyData.companyName,
        cin: companyData.cin,
        authorizedCapital: companyData.authorizedCapital,
        paidUpCapital: companyData.paidUpCapital,
        nominalShareValue: companyData.nominalShareValue,
        userId: companyData.userId
      });

      // Save to companies collection with error handling
      try {
        const companyRef = doc(db, 'companies', userCredential.user.uid);
        await setDoc(companyRef, companyData);
      } catch (firestoreError) {
        // If Firestore save fails, delete the auth user to maintain consistency
        await userCredential.user.delete();
        console.error('Firestore error:', firestoreError);
        throw new Error('Failed to save company data. Please try again.');
      }
      
      // Set the company data in context
      setCompanyData(companyData);

      return {
        user: userCredential.user,
        companyData
      };
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const googleRegister = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      // Check if user profile exists
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      
      if (!userDoc.exists()) {
        // This is a new user, but we need additional info
        // Return isNewUser true so the UI can handle collecting additional info
        return { 
          ...result.user, 
          isNewUser: true,
          email: result.user.email,
          firstName: result.user.displayName?.split(' ')[0] || '',
          lastName: result.user.displayName?.split(' ').slice(1).join(' ') || ''
        };
      }
      
      return { ...result.user, isNewUser: false };
    } catch (error) {
      console.error('Google registration error:', error);
      throw error;
    }
  };

  const login = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const googleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      // Check if user profile exists
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      
      if (!userDoc.exists()) {
        return { 
          ...result.user, 
          isNewUser: true,
          email: result.user.email,
          firstName: result.user.displayName?.split(' ')[0] || '',
          lastName: result.user.displayName?.split(' ').slice(1).join(' ') || ''
        };
      }
      
      return { ...result.user, isNewUser: false };
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  };

  const logout = () => {
    return signOut(auth);
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const companyRef = doc(db, 'companies', user.uid);
        const companySnap = await getDoc(companyRef);
        if (companySnap.exists()) {
          setCompanyData(companySnap.data());
        }
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
        setCompanyData(null);
      }
      setLoading(false);
      setAuthLoading(false);
    });

    return unsubscribe;
  }, [auth, db]);

  const value = {
    currentUser,
    companyData,
    setCompanyData,
    loading,
    authLoading,
    register,
    login,
    logout,
    googleLogin,
    googleRegister,
    resetPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!authLoading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;